import React from "react"

import Layout from "../components/Layout"

const InvalidPage = () => {
  return (
    <Layout>
      <div
        className="flex items-center justify-center"
        style={{ height: "40vh" }}
      >
        <div className="text-center">
          <h1 style={{ fontSize: "1.4em", fontWeight: "bold" }}>
            Verification Failed!
          </h1>
          <p>Your verification link is either invalid or has expired.</p>
        </div>
      </div>
    </Layout>
  )
}

export default InvalidPage
